.download-app{
    small {
        font-size: clamp(0.7rem, 0.8vw, 1rem);
        font-family: "LatoSemiBold";
        letter-spacing: 3px;

        @media only screen and (max-width: 767px) {
            font-size: 12px;
        }
    }

    .bigger-title-size {
        font-size: 3.1vw;

        @media only screen and (max-width: 767px) {
            font-size: 38px;
        }
    }
    .bigger-text-size{
        font-size: 1.1vw;

        @media only screen and (max-width: 767px) {
            font-size: 20px;
        }
    }

    .store-button{
        width: 220px;
        height: auto;
        @media screen and (max-width: 1550px) {
            width: 170px;
          }
        
        @media screen and (max-width: 500px) {
            width: 300px;
            height: 40px;
          }
    }

    .phones-img {

        @media screen and (min-width:1600px){
            position: absolute;
            left: -80px !important;
            top: -105px !important;
            width:1100px !important;
        }

        @media screen and (max-width:1550px){
            position: absolute;
            left: -190px !important;
            top: -80px !important;
            width:900px !important;
        }

        @media screen and (min-width:768px) and (max-width:1200px){
            position: absolute;
            left: 30px !important;
            top: 0px !important;
            width:500px !important;
        }

        @media only screen and (max-width: 767px) {
          position: relative;
          left: -100px !important;
          top: 0px !important;
          width: 120vw !important;
        }
      }
}