.about-us {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.95) 1%, rgba(0, 0, 0, 0.7) 46%), url(../Assets/AboutUs-Background.jpg);
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat !important;
    color: #f7f7f7;

    small{
      font-family: "LatoSemiBold";
      font-size: 0.8vw;
    }

    h2{
      font-size: 2.6041666666667vw;
      font-family: "LatoSemiBold";
    }

    .more-letter-spacing{
      letter-spacing: 1px;
    }

    .bigger-title-size{
      font-size: 2.8rem;
      letter-spacing: 2px;
      @media screen and (min-width:1600px){
        font-size: 3.5rem;
      }

      @media screen and (max-width:600px){
        font-size:2rem;
      }
    }

    .description{
      font-size: 1.1vw;
    }

    .logo-container {
      display: flex;
      justify-content: end;
      align-items: center;

      @media screen and (max-width: 770px) {
        justify-content: start;
      }
    }

    .about-us-description{
      padding: 80px 0 80px 0;
      color: #f7f7f7;
    }

    .hours-container {
      h4 {
        font-size: 1.4vw;
        font-family: "LatoBold";
      }

      p {
        font-size: 14px;
        font-family: "LatoLight";
        // white-space: nowrap;
      }
      @media screen and (min-width:1600px){
        h4 {
          font-size: 1.5vw;
          font-family: "LatoBold";
        }
  
        p {
          font-size: 18px;
          font-family: "LatoLight";
          // white-space: nowrap;
        }
      }
      @media screen and (max-width:600px){
        padding-left: 0;
      }
    }

    .container {
      max-width: calc(100% - 25%);

      @media screen and (max-width: 770px) {
        max-width: calc(100%);
      }
    }

    .location{
      margin-top: 85px;
      @media screen and (min-width:1600px){
        margin-top: 95px;
      }
      @media screen and (max-width:600px){
        margin-top: 10px;
      }
    }

    .gallery-container {
      margin-top: 30px;
      .about-img {
        .column-container {
          text-align: center;
          border-radius: 15px;
          padding: 0;
          overflow: hidden;
          margin-bottom: 40px;
          img {
            width: 90%;
            border-radius: 16.2px;
          }

          .inner-about {
            position: absolute;
            background: rgba(0, 0, 0, 0.7);
            border-radius: 15px;
            margin-left: 5% !important;
            bottom: 0;
            width: 90%;
            height: 135px;
            -webkit-transition: all 0.5s 0s ease-in-out;
            -moz-transition: all 0.5s 0s ease-in-out;
            -o-transition: all 0.5s 0s ease-in-out;
            transition: all 0.5s 0s ease-in-out;

            .title {
              font-size: 1.5vw;
              color: #f7f7f7;
              padding-bottom: 10px;
              @media screen and (max-width:750px){
                font-size: 25px;
              }
            }

            p {
              font-size: 1.2vw;
              padding: 5px 15px 5px 15px;
              font-weight: 400;
              height: 0;
              color: #f7f7f7;
              transition-delay: 0.5s;
              -webkit-transition: all 0.3s 0.3s ease;
              -moz-transition: all 0.3s 0.3s ease;
              -o-transition: all 0.3s 0.3s ease;
              transition: all 0.3s 0.3s ease;
              @media screen and (max-width:750px){
                font-size: 18px;
              }

              @media screen and (max-width:1225px) and (min-width:750px){
                padding: 16px 15px 5px 15px;
              }

              @media screen and (max-width:300px){
                font-size: 14px;
              }
            
            }

            img {
              width: 60px;
              display: block;
              margin: auto;
              -webkit-transition: all 0.5s 0.2s ease;
              -moz-transition: all 0.5s 0.2s ease;
              -o-transition: all 0.5s 0.2s ease;
              transition: all 0.5s 0.2s ease;
              border-radius: 0;
              width: 60px;
              height: 60px;
            }
          }
          &:hover {
            .inner-about {
              height: 100%;
              padding: 3.125rem 0 0;

              p {
                display: block;
                height: auto;
                color: #f7f7f7;
              }

              img {
                width: 5vw;
                height: auto;
              }
            }
          }
        }
      }

      @media screen and (min-width:1600px){
        .about-img{
          .column-container{
            .inner-about{
              height: 160px;
              img{
                height: 70px;
                width: 70px;
              }
            }

          }
        }
      }
    }
}

@media only screen and (max-width: 992px) {
  .about-us-description {
    padding: 10px 30px !important;
    small {
      font-size: 12px;
    }

    h2 {
      padding: 10px 0 !important;
      font-size: 38px;
    }

    .description {
      font-size: 16px;
      margin-bottom: 20px;
    }

    .hours-container {
      h4 {
        font-size: 24px;
      }

      p {
        font-size: 16px;
        // white-space: nowrap;
      }
    }

    .location {
      h4 {
        font-size: 24px;
      }

      p {
        font-size: 16px;
      }
    }
  }

  .gallery-container {
    padding: 0 15px;
    .about-img {
      .inner-about {
        &:hover {
          padding: 2rem 0 0 !important;

          img {
            width: 10vw !important;
            height: auto !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) and (min-width: 400px) {
  .about-img {
    .inner-about {
      &:hover {
        padding: 10rem 0 0 !important;

        img {
          width: 10vw !important;
          height: auto !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .about-img {
    .inner-about {
      &:hover {
        padding: 1rem 0 0 !important;

        img {
          width: 10vw !important;
          height: auto !important;
        }
      }
    }
  }
}