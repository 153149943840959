.welcome-page {
  padding: 25% 0 5% 0;
  display: table;
  text-align: center;
  width: 100%;
  color: #fff;
  height: 100vh;

  p {
    font-size: 1.5vw;
    text-transform: uppercase;
    font-family: "Montserrat-Medium";
    letter-spacing: 3px;
  }

  h1 {
    font-size: 7.4vw;
    font-weight: 400;
  }

  .play-btn {
    margin-top: 16vh;

    p {
      font-size: 0.8vw;
      margin: 20px 0 0;
    }
  }
}

@media only screen and (max-width: 992px) {
  .welcome-page {
    height: 100vh;
    padding: 200px 0 0 0;
    display: table;
    text-align: center;
    width: 100%;
    color: #fff;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.4) 5%, rgba(0, 0, 0, 0.22) 100%), url(./../Assets/welcomebackg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 65% 35%;
    // background-size: 100% 115vh;

    p {
      font-size: 14px;
      text-transform: uppercase;
    }
  
    h1 {
      font-size: 68px;
      font-weight: 400;
    }
  
    .play-btn {
      margin-top: 50%;
  
      p {
        font-size: 13px;
        margin: 20px 0 0;
      }
    }
  }
}