.service {
    background-image: url(../Assets/newService.png);
    min-height: 500px;
    background-size: cover;
    background-repeat: no-repeat !important;
    color: #f7f7f7;
    // padding-bottom: 50px;

    @media only screen and (max-width: 767px) {
        background-image: linear-gradient(271deg, rgba(4, 6, 5, 0.35) 96%, rgba(9, 12, 11, 0.72) 42%, rgba(4, 6, 5, 0.9) 1%) url(../Assets/service-mobile.jpg);
       background-repeat: no-repeat;
       background-size: cover;
        // background-size: 100% 100%;
        background-position: center;
        padding-bottom: 0px;
    }

    .more-letter-spacing{
        letter-spacing: 2px;
    }

    small{
        font-size: clamp(0.6rem, 0.8vw, 0.9rem);
        text-transform: uppercase;
        font-family: "LatoSemiBold";

        @media only screen and (max-width: 767px) {
            font-size: 12px;
        }
    }

    h2 {
        font-family: "LatoSemiBold";
        font-size: clamp(2.8rem, 3.125vw, 3.125rem);
    }

    .service-container {
        padding: 100px 0 30px 0;

        @media only screen and (max-width: 992px) {
            padding: 50px 30px;
        }

        @media screen and (min-width:1400px) and (max-width:1550px){
            padding: 100px 100px 30px 100px;
        }
    }

    .bigger-title-size {
        font-size: 3.1vw;

        @media only screen and (max-width: 767px) {
            font-size: 38px;
        }
    }

    .smaller-title-size {
        font-family: "LatoSemiBold";
        font-size: clamp(2.4rem, 2.6041666666667vw, 2.9rem);

        @media only screen and (max-width: 767px) {
            font-size: 34px;
        }
    }

    .middle {
        font-size: clamp(0.8rem, 1.1vw, 1.3rem);
        width: 50%;

        @media only screen and (max-width: 767px) {
            font-size: 16px;
            width:90%;
        }
    }

    .divider {
        // border-bottom: 4px solid #a77243;
        max-width: 14%; 
        height: 4px;
        margin-left:0;
        color: #a77243;

        @media screen and (max-width:600px){
            max-width: 40%;
        }
    }

    .more-spacing-mobile{
        @media screen and (max-width:600px){
            padding-bottom: 30px;
        }
    }
}