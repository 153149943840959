@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: "LatoSemiBold";
  src: local("LatoSemiBold"),url("./Assets/Fonts/LatoSemibold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"),url("./Assets/Fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "LatoBold";
  src: local("LatoBold"),url("./Assets/Fonts/Lato-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "LatoLight";
  src: local("LatoLight"),url("./Assets/Fonts/Lato-Light.ttf") format("truetype");
}

@font-face {
  font-family: "LatoMedium";
  src: local("LatoMedium"),url("./Assets/Fonts/Lato-Medium.ttf") format("truetype");
}

html,
body {
  overflow-x: hidden !important;
}

.md-form input[type="text"]:not(.browser-default):focus:not([readonly]){
  border-bottom: 1px solid #f7f7f7 !important;
  box-shadow: 0 1px 0 0 #f7f7f7 !important;
}

.md-form input[type="text"]:not(.browser-default):focus:not([readonly])+label{
  color: #f7f7f7 !important;
}

.collapser-div .navbar-toggler .navbar-toggler-icon{
   background-image: url("./Assets/menu-btn.svg") !important;
}

.darker-collapser-div .navbar-toggler .navbar-toggler-icon{
  background-image: url("./Assets/times-logo.svg") !important;
}

body {
  position: relative;
  font-family: "Lato", sans-serif !important;
  font-weight: 400;
  background-image: linear-gradient(179deg, rgba(0, 0, 0, 0.8) 1%, rgba(0, 0, 0, 0.7) 10%, rgba(0, 0, 0, 0.22) 100%), url(./Assets/welcomebackg.jpg);
  font-size: 22px;
  background-repeat: no-repeat;
  padding: 0;
  background-size: 100% 115vh;

  &.overflow-y-scroll,
  &.overflow-y-scroll.modal-open .modal {
    overflow-y: hidden !important;
  }

  .container {
    color: #ffffff;
    font-size: 12px;
  }

  .navbar.navbar-dark .navbar-toggler {
    color: transparent !important;
  }

  .upper-top {
    // position: absolute;
    width: 100%;

    li {
      margin-top: 8px;
      cursor: pointer;
      font-size: 16px;
    }
  }

  .footer-content{
    color: #5f5f5f;
    
      @media screen and (min-width:1400px) and (max-width:1550px){
          padding: 0 100px;
      }
    
    .footer-logo-sizing{
      width: 120px;
      height: 120px;
      @media screen and (max-width:600px){
        width: 150px;
        height: 150px;
        margin-left: 20px;
      }
    }
  }

  .hover {
    cursor: pointer;
  }

  .moreMarginTop{
    @media screen and (max-width:600px){
      margin-top: 60px;
    }
    @media screen and (max-width:320px){
      margin-top: 100px;
    }
  }

  .f7-color{
    color: #f7f7f7;
  }

  .brown-color-used {
    color: #a77243;
  }

  .bigger-title-size {
    font-size: 2.6rem;
    // font-size: clamp(1rem, 1rem, 1rem);
    line-height: 1.2;
  }

  .more-button {
    font-size: clamp(0.8rem, 0.9vw, 0.9rem);
    font-family: "LatoSemiBold";
    border: none;
    border-radius: 10px;
    padding: 17px 45px;
    background-image: linear-gradient(259deg, #d57c2d 81%, #6b3e17);
    // font-weight: 600;
    color: #e6e7e9;
    @media screen and (max-width:600px){
      margin-left: -10px;
    }
  }

  .full-menu-button{
    font-size: clamp(0.8rem, 0.9vw, 0.9rem);
    font-family: "LatoSemiBold";
    border: none;
    border-radius: 10px;
    padding: 17px 32px;
    background-image: linear-gradient(255deg, #d57c2d 80%, #6b3e17);
    // font-weight: 600;
    color: #e6e7e9;
  }

  .middle-contact-btn {
    @media only screen and (max-width: 992px) {
      text-align: left !important;
    }
  }

  .element {
    color: #fff !important;
    height: 100vh;
    // background-image: linear-gradient(179deg, rgba(0, 0, 0, 0.8) 1%, rgba(0, 0, 0, 0.7) 10%, rgba(0, 0, 0, 0.22) 100%), url(./Assets/home-bg.jpg);
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-size: top center
  }

  // .welcome-page {
  //   padding: 63vh 0 0;
  //   display: table;
  //   text-align: center;
  //   width: 100%;

  //   p {
  //     font-size: 1.4vw;
  //     text-transform: uppercase;
  //   }

  //   h1 {
  //     font-size: 6vw;
  //     font-weight: 400;
  //   }

  //   .play-btn {
  //     margin-top: 16vh;

  //     p {
  //       font-size: 0.8vw;
  //       margin: 20px 0 0;
  //     }
  //   }
  // }

  .discover-rule {
    // background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.95) 1%, rgba(0, 0, 0, 0.85) 46%),
    //   url(./Assets/discoverRules.png);

    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.95) 1%, rgba(0, 0, 0, 0.7) 46%), url(./Assets/discoverRules.jpg);
    min-height: auto;
    background-size: 100%;
    background-repeat: no-repeat;
    color: #f7f7f7;

    @media screen and (max-width:1220px) and (min-width:768px){
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.95) 1%, rgba(0, 0, 0, 0.7) 46%), url(./Assets/discoverRules.jpg);
      min-height: auto;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    @media screen and (max-width:768px){
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.95) 1%, rgba(0, 0, 0, 0.85) 46%), url(./Assets/rules-mobile-background.png);
    background-size: 200% 100%;
    background-position: center;
    height: 95vh;
    min-height:820px;
    background-repeat: no-repeat;
    }

    @media screen and (max-width:320px){
      height: 95vh;
      min-height:1000px;
    }

    .rule-title-size{
      font-size: clamp(2.1rem, 3.2vw, 3.75rem);
    }

    .middle {
      font-size: clamp(1rem, 1.15vw, 1.375rem);
    }

    ul {
      margin: 0 30px;
      @media screen and (max-width:600px){
        margin: 0 20px;
        padding: 0 10px !important;
      }
      li {
        font-size: clamp(1rem, 1.15vw, 1.375rem);
      }
    }

    ul li::marker {
      color: #d57c2d;
    }

    h2 {
      font-family: "LatoSemiBold";
      font-size: clamp(3.5rem, 3.75vw, 3.75rem);
    }

    small {
      font-family: "LatoSemiBold";
      font-size: clamp(0.8rem, 1vw, 1rem);
    }

    .library {
      height: 95%;
      padding: 2rem 0 1rem 0;
      max-width: 90%;

      @media screen and (max-width:1024px) and (min-width:768px){
      height: 75%;
      padding: auto 0;
      max-width: 90%;
      }
    }

    @media only screen and (max-width: 992px) {
      .discover-rules {
        padding: 0 15px;

        small {
          font-size: 12px;
        }

        // h2 {
        //   font-size: 38px;
        // }
      }
    }
  }

  .black-tie {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.95) 1%, rgba(0, 0, 0, 0.85) 46%),
      url(./Assets/discoverRules.jpg);
    min-height: 100vh;
    background-size: 101% 100%;
    background-repeat: no-repeat;
    color: #f7f7f7;

    .full-height-container {
      min-height: 90vh;
    }
  }

  .become-member {
    background-image: linear-gradient(to left, rgba(4, 6, 5, 0.35) 97%, rgba(9, 12, 11, 0.86) 37%, rgba(4, 6, 5, 0.83) 0%),
      url(./Assets/car-back.png);
    min-height: 60vh;
    height: 745px;
    background-size: 101% 100%;
    background-repeat: no-repeat;
    color: #f7f7f7;

    .full-height-container {
      min-height: 60vh;
      height: 745px;
    }
  }

  .our-gallery {
    background-color: #000;
    min-height: 100vh;
    color: #f7f7f7;
  }

  .download-app {
    background-color: #0e1014;
    // min-height: 50vh;
    // height: 623px;
    padding: 60px 0;
    color: #f7f7f7;

    @media screen and (max-width: 992px) {
      padding: 30px !important;
    }

    small {
      font-size: clamp(0.7rem, 0.8vw, 0.8rem);
      font-family: "LatoSemiBold";
    }

    h2 {
      font-size: clamp(2.8rem, 3.125vw, 3.125rem);
      font-family: "LatoSemiBold";
    }

    p {
      font-size: clamp(0.9rem, 1.1vw, 1.1rem);
      font-family: "LatoBold";
    }

    .download-image {
      width: 402px;
      height: 57px;

      @media screen and (max-width: 500px) {
        width: 300px;
        height: 40px;
      }
    }

    .phones-img {
      position: absolute;
      left: -20%;
      top: -40%;
      width: 60vw;

      @media only screen and (max-width: 767px) {
        position: relative;
        left: -100px !important;
        top: 0px !important;
        width: 120vw;
      }
    }
  }

  .contactus {
    background-color: #000;
    min-height: 55vh;
    color: #f7f7f7;
    font-weight: 700;
    padding: 60px 60px 0px 60px;

    @media only screen and (max-width: 767px) {
      padding: 10px 30px !important;

      .inputs {
        padding: 0px !important;
      }
    }

    .desktop-padding{
      @media screen and (min-width:1400px) and (max-width:1550px){
          padding: 0 100px;
      }
    }

    small {
      font-size: clamp(0.7rem, 0.8vw, 0.8rem);
      font-family: "LatoSemiBold";
    }

    .bigger-title-size{
      font-size: 2.8rem;
      letter-spacing: 2px;
      @media screen and (min-width:1600px){
        font-size: 3.5rem;
      }

      @media screen and (max-width:600px){
        font-size:2.2rem;
      }
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #676767;
      opacity: 1; /* Firefox */
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #676767;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
      color: #676767;
    }

    .md-form label{
      color: #676767 !important;
    }

    .social-buttons{
      width: 21px;
      height: 21px;

      @media screen and (max-width:600px){
        width: 26px;
        height: 26px;
      }
    }


    .more-letter-spacing{
      letter-spacing: 2px;
    }

    h2 {
      font-size: clamp(2.8rem, 3.125vw, 3.2rem);
      font-family: "LatoSemiBold";
    }

    p {
      color:#8b8b8b;
      font-size: clamp(0.9rem, 1.1vw, 1.1rem);
    }

    .md-form input[type="text"]:not(.browser-default) {
      border-bottom: 0px solid;
      background: #121213;
      padding: 10px;
      border-radius: 6px;
      color: #fff;
    }

    .md-form {
      margin: 12px 0px;
    }

    .md-form>label {
      padding: 0px 10px;
      font-size: clamp(0.8rem, 1vw, 1rem) !important;
      font-family: "LatoMedium" !important;
    }

    .message {
      width: 100%;
      background: #121213;
      border: 0px;
      color: #fff;
      font-family: "LatoMedium";
      font-size: clamp(0.9rem, 3.1vw, 1.1rem);
      resize: none;
      padding: 10px;
    }
  }

  .navbar {
    background: url(./Assets/menu-bg.svg) no-repeat;
    top: 63px;
    color: #fff;
    width: 90%;
    margin: 0 auto;
    box-shadow: none;
    background-size: 100% auto;
    padding: 0 !important;
    background-color: transparent !important;

    ul {
      width: 80%;
      margin: 0 14%;
      padding: 0;
      display: block;
    }

    li {
      width: 15%;
      margin: 15px 0 0;
      float: left;
      text-align: center;
      font-family: "LatoSemiBold";
      font-size: clamp(0.8rem, 0.85vw, 0.85rem);

      @media screen and (max-width:1125px) and (min-width:992px){
        margin: 10px 0 0;
      }

      .aboutus,
      .members {
        float: left !important;
      }

      .media,.contactUs{
        float: right !important;
      }

      a:hover {
        color: #d57c2d !important;
      }

      li {
        width: auto;
      }
    }

    li:nth-child(3) {
      width: 40%;
      margin: 0;

      li {
        margin: auto;
        display: table;
        float: none;
        width: 80%;
      }
    }

    .logo {
      background: url(./Assets/logo-bg.svg) no-repeat;
      background-position: top center;
      background-size: 14vw;
      display: block;
      width: 100%;
      margin: -9px auto;

      img {
        width: 8vw;
        margin: 0.5vw 2vw 3vw 2.2vw;
      }
    }

    &.top-nav-collapse {
      top: 0;
    }

    &.top-nav-collapse {
      top: -7px !important;
      background: url(./Assets/bg-menu.svg) no-repeat;
      background-size: 100% auto;
      padding: 0 !important;
      background-color: transparent !important;
      background-position: center -5px;

      .logo {
        background: url(./Assets/MC-Logo-bg.svg) no-repeat;
        background-position: top center;
        background-size: 14vw;
      }
    }
  }
}

.footer {
  background-color: #000;
  color: #8b8b8b;
  font-weight: normal;
  min-height: 24vh;

  hr {
    border: 1px solid #555;
  }

  img {
    cursor: pointer;
    width: 6vw;
    height: 11vh;

    @media only screen and (max-width: 992px) {
      width: 40vw;
      height: 20vh;
    }
  }

  .under-footer {
    cursor: pointer;
    li {
      font-size: clamp(0.6rem, 0.8vw, 0.8rem);
    }
  }
  
  .copyright {
    cursor: default;
    font-size: clamp(0.6rem, 0.8vw, 0.8rem);
  }

  .ul-list {
    padding: 85px 20px 0 0;
    
    li {
      font-size: clamp(0.6rem, 0.67vw, 0.67rem);
    }
  }

  .footer-menu-list {
    width: 100%;
    margin: 0 14%;
    padding: 0;
    display: block;
    list-style-type: none;
    text-align: center;

    li {
      margin-bottom: 20px !important;
    }
  }
}

.grey-bg {
  background-color: #848484;
  color: #373631;
  border-radius: 3px;
  margin-right: 10px;
  padding: 2px 4px;
  font-size: 12px;
}

@media only screen and (max-width: 535px) {
  body {
    background: rgb(44, 42, 40);
    background: linear-gradient(180deg, rgba(44, 42, 40, 0.4) 0%, rgba(44, 42, 40, 1) 55%),
      url(./Assets/hp-bg-mobile.jpg);
    width: 100%;
    height: auto;
    background-size: 100% auto;
    background-repeat: no-repeat;
    color: #fff !important;
  }
}

.backtotop-btn {
  background-color: transparent;
  position: fixed;
  bottom: 20px;
  right: 0;
  margin-right: 20px;
  cursor: pointer;
  z-index: 9;
}

.collapser-div,
.darker-collapser-div {
  display: none !important;
}

@media only screen and (max-width: 992px) {
  body {
    font-family: "Lato", sans-serif !important;
    background-image: linear-gradient(179deg, rgba(0, 0, 0, 0.8) 1%, rgba(0, 0, 0, 0.7) 10%, rgba(0, 0, 0, 0.22) 100%), url(./Assets/hp-bg-desktop.jpg);
    font-size: 22px;
    background-size: cover !important;
    background-repeat: no-repeat;
    padding: 0;
    background-size: 100% 100%;
    position: relative;
  }

  .collapser-div {
    width: 100%;
    display: flex !important;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .darker-collapser-div {
    width: 100%;
    display: flex !important;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 1);
  }

  .navbar-collapse {
    background-color: #000;

    .navbar-nav {
      justify-content: center;
      align-items: center;
      display: flex;
      margin: 0;
      padding-bottom: 40px;

      li {
        width: auto;
      }
    }
  }

  .navbar {
    background: transparent !important;
  }
}

.modal-fluid {
    width: 100% !important;
    max-width: 90% !important;
    max-height: 94vh !important;
    height: 100vh !important;
    margin: 3vh 3% !important;
}

.custom-blurry-modal {
  height: 100vh !important;
  // padding: 80px;
  // margin: 0 !important;

  @media only screen and (max-width: 992px) {
    padding: 0 0;
  }

  .modal-content {
    background-color: rgba(45, 43, 41, 0.8);
    width: 100%;
    color: #fff;

    small {
      font-size: clamp(0.6rem, 0.8vw, 0.8rem);
      text-transform: uppercase;

      @media only screen and (max-width: 992px) {
        font-size: 12px;
      }
    }

    h2 {
      font-size: clamp(2.8rem, 3.125vw, 3.3rem);
      font-family: "LatoSemiBold";

      @media only screen and (max-width: 992px) {
        font-size: 38px;
        margin-bottom: 10px;
      }
    }

    .center-large{
      @media screen and (min-width:1300px){
        text-align: center;
      }
    }

    .margin-on-bigger-screen{
      @media screen and (min-width:1300px){
        margin-left: 50px;
      }
    }

    p {
      font-size: clamp(0.9rem, 1.1vw, 1.1rem);

      @media only screen and (max-width: 992px) {
        font-size: 16px;
        margin-bottom: 40px;
      }
    }

    .scrollable {
      overflow-y: scroll;
      max-height: 80vh;

      p {
        font-size: clamp(0.9rem, 1.1vw, 1.1rem);
      }

      &::-webkit-scrollbar {
        background-color: transparent;
      }
    }

    .x-times {
      background-color: transparent;
      border: none;
      color: #fff;
    }

    .accordion-item {
      background-color: transparent !important;
      border: none;
      padding-bottom: 15px;
      border-bottom: 1px solid #707070;

      @media only screen and (max-width: 992px) {
        padding-bottom: 0px;
        padding-left: 10px !important;
      }
    }

    .accordion-header {
      margin-bottom: 0 !important;

      button {
        font-size: clamp(0.9rem, 1.1vw, 1.1rem) !important;

        @media only screen and (max-width: 992px) {
          font-size: 16px !important;
        }
      }
    }

    .accordion-body {
      font-size: clamp(0.9rem, 1.1vw, 1.1rem) !important;
      font-family: "LatoLight";
      
      @media only screen and (max-width: 992px) {
        font-size: 16px !important;
        background-color: #000 !important;
      }
    }

    .accordion-button {
      background-color: transparent;
      color: #fff;
    }

    .accordion-button:not(.collapsed) {
      @media only screen and (max-width: 992px) {
        background-color: #000 !important;
      }
    }

    .accordion-button:not(.collapsed)::after {
      background-color: transparent !important;
      flex-shrink: 0;
      height: 1.25rem;
      margin-left: auto;
      content: "";
      background-image: url(./Assets/minus.svg);
      background-repeat: no-repeat;
      background-size: 1rem;
      transition: transform .2s ease-in-out;
    }

    .accordion-button::after {
      flex-shrink: 0;
      width: 1.25rem;
      height: 1.25rem;
      margin-left: auto;
      content: "";
      background-image: url(./Assets/plus.svg);
      background-repeat: no-repeat;
      background-size: 1rem;
      transition: transform .2s ease-in-out;
    }

    .accordion-button:focus {
      box-shadow: none !important;
    }
  }
}