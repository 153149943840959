.our-menu {
  background-color: #0e1014;
  // min-height: 100vh;
  color: #f7f7f7;
  // padding: 50px 0;


  @media only screen and (max-width:992px) {
    .menu {
      padding: 0 15px;
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .transparent-model{
    .modal-content{
        position: relative;
        box-shadow: none !important;
        background-color: transparent !important;
        .modal-header{
            border-bottom: none !important;
            .close{
                color: #f7f7f7 !important;
            }
        }

        .menu-times-btn {
          position: absolute;
          top: -10px;
          right: -35px;
          background: transparent;
          color: #fff;
          font-size: 20px;
          border: none;
        }
    }
  }

  .desktop-view {
    display: flex;

    @media only screen and (max-width: 992px) {
      display: none;
    }
  }

  .desktop-padding{
    @media screen and (min-width:1400px) and (max-width:1550px){
        padding: 0 100px;
    }
  }

  .mobile-view {
    display: none;

    @media only screen and (max-width: 992px) {
      display: flex;
    }
  }

  .left-menu-col-image {
    padding: 0 50px 0 11px;

    @media only screen and (max-width: 992px) {
      padding: 0;
    }
  }

  .left-menu-col {
    padding: 0 40px 0 0;

    .menu-type {
      font-size: clamp(1rem, 1.125vw, 1.125rem);
    }

    

    .dish-ingredients {
      font-size: clamp(0.9rem, 0.9vw, 1.125rem);
    }

    @media only screen and (max-width: 767px) {
      padding: 0 10px;

      .menu-type {
        font-size: 14px;
      }

      

      .dish-ingredients {
        font-size: 16px;
      }
    }
  }

  .right-menu-col {
    padding: 0 0 0 50px;

    .menu-type {
      font-size: clamp(1rem, 1.125vw, 1.125rem);
    }

   

    // small {
    //   font-family: "LatoSemiBold";
    //   font-size: clamp(0.8rem, 1vw, 1rem);
    // }

    h1 {
      font-size: clamp(1.8rem, 2vw, 2rem);
    }

    .menus-description {
      font-size: clamp(1rem, 1.1vw, 1.1rem);
    }
    
    .dish-ingredients {
      font-size: clamp(0.9rem, 0.9vw, 1.125rem);
    }

    @media only screen and (max-width: 767px) {
      padding: 0 10px;

      small {
        font-size: 12px;
      }

      h2 {
        text-align: left;
        font-size: 38px;
      }

      .menus-description {
        font-size: 16px;
      }

      .menu-type {
        font-size: 14px;
      }

      

      .dish-ingredients {
        font-size: 16px;
      }
    }
  }

  .menu-image-style {
    border-radius: 17px;
    width: 100%;
    height: auto;

    @media screen and (max-width: 600px) {
      width: 335px;
    }
  }
  .menus-title{
    font-family: "LatoSemiBold";
    font-size: clamp(2.9rem, 3.2vw, 3.75rem);
    padding: 0;
  }
  .menus-description{
    font-size: clamp(1.2rem, 1.375vw, 1.375rem);
  }

  .menu-type{
    font-family: "LatoSemiBold";
    padding: 20px 0 20px 0;
    font-size: clamp(1rem, 1.125vw, 1.125rem);
  }

  .dish-image {
    height: 50px;
    width: 50px;
    border-radius: 5px;
    float: right;

    @media screen and (max-width:600px){
      height: 60px;
      width: 60px;
    }

    @media screen and (max-width:320px){
      height: 50px;
      width: 50px;
    }

    @media screen and (max-width:300px){
      height: 45px;
      width: 45px;
    }

  }

  .dishName-container{
    display: inline-block;
    margin-right: 25px; 
  }

  .dish-name{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;  
    font-size: clamp(1.3rem, 1.3vw, 1.6875rem);

    @media screen and (max-width:380px){
      font-size: 18px;
    }

    @media screen and (max-width:330px){
      font-size: 16px;
    }

    @media screen and (max-width:300px){
      font-size: 13.5px;
    }
  }

  .border-added {
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    margin-bottom: 15px;
  }

  .dish-ingredients{
    color: rgba(247, 247, 247, 0.5);
    text-overflow: ellipsis;
    font-size: clamp(0.8rem, 0.9vw, 1.125rem);
    // margin-right: 20px;
  }

  .more-button {
    font-family: "LatoSemiBold";
    font-size: clamp(1rem, 1.125vw, 1.125rem);
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
  }
}