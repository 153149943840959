.carousel-indicators {
    display: none !important;
}

.carousel-control-prev-icon {
    position: absolute;
    background-image: url('../Assets/capturedmoments/previousIcon.svg') !important;
    left: -100px !important;
    height: 70px !important;
    width: 70px !important;
    top: 45%;
    @media screen and (max-width:600px){
        display:none;
    }
}

.carousel-control-next-icon {
    position: absolute;
    background-image: url('../Assets/capturedmoments/nextIcon.svg') !important;
    right: -100px !important;
    height: 70px !important;
    width: 70px !important;
    top: 45%;
    @media screen and (max-width:600px){
        display:none;
    }
}
.mdb-gallery{
    overflow:hidden !important;
}

.our-gallery {
    .desktop-padding{
        @media screen and (min-width:1400px) and (max-width:1550px){
            padding: 0 70px;
        }
    }

    small {
        font-size: clamp(0.7rem, 0.8vw, 0.92rem);
        font-family: "LatoSemiBold";

        @media only screen and (max-width: 767px) {
            font-size: 12px;
        }
    }

    .more-letter-spacing{
        letter-spacing: 2px;
    }

    h2 {
        font-size: clamp(2.8rem, 3.125vw, 3.55rem);
        font-family: "LatoSemiBold";

        @media only screen and (max-width: 767px) {
            font-size: 38px;
        }
    }

    .transparent-model{
        .modal-content{
            box-shadow: none !important;
            background-color: transparent !important;
            .modal-header{
                border-bottom: none !important;
                .close{
                    color: #f7f7f7 !important;
                }
            }
        }
    }

    .half-sized-image{
        height: auto;
        object-fit: contain;
        overflow: hidden;
    }
}